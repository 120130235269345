import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Heads from '@/components/head/index';
import auditDialog from '@/components/auditDialog/index';
import { getAuditDetail } from '@/api/ceshi_xiugai/order';
import { getIndividualOrderList, getUserKpiInfo, getSalesActivityInfo } from '@/api/personalAnalysis';
export default {
  name: 'personalAnalysis',
  components: {
    Heads: Heads,
    auditDialog: auditDialog
  },
  data: function data() {
    return {
      title: '个人统计',
      tableSaleData: {
        obj1: [],
        obj2: []
      },
      tableOrderData: [],
      auditDetailData: {},
      //审核详情
      orderTimeValue: '2022',
      orderTimeValue1: '2022/4',
      dateOptions: [],
      dateOptionsValue: [],
      // 数据的总对象
      allDataObj: {
        professional_level: '-',
        user_level: '-',
        individual_target_sales: '-',
        individual_actual_sales: '-',
        individual_no_target: '-',
        percent1: 0,
        group_target_sales: '-',
        group_actual_sales: '-',
        group_no_target: '-',
        percent2: 0,
        is_leader: 0
      },
      taskLoading: false,
      orderLoading: false,
      saleLoading: false
    };
  },
  computed: {
    sumTimeFilter: function sumTimeFilter() {
      return function (val) {
        // if (val == 0) {
        //     return '-';
        // }
        if (val == 0) {
          return '0';
        }
        var absVal = Math.abs(val);
        var str = '';
        if (Number(absVal) >= 24 * 60 * 60) {
          var day = parseInt(Number(absVal) / (24 * 60 * 60));
          var yu = Number(absVal) % (24 * 60 * 60);
          var hour = parseInt(yu / (60 * 60));
          var min = parseInt(yu % (60 * 60) / 60);
          var sec = parseInt(yu % (60 * 60) % 60);
          str = "".concat(day, "\u5929").concat(hour, "\u65F6").concat(min, "\u5206").concat(sec, "\u79D2");
        } else if (Number(absVal) >= 60 * 60) {
          var _hour = parseInt(Number(absVal) / (60 * 60));
          var _min = parseInt(Number(absVal) % (60 * 60) / 60);
          var _sec = parseInt(Number(absVal) % (60 * 60) % 60);
          str = "".concat(_hour, "\u65F6").concat(_min, "\u5206").concat(_sec, "\u79D2");
        } else if (Number(absVal) >= 60) {
          var _min2 = parseInt(Number(absVal) / 60);
          var _sec2 = parseInt(Number(absVal) % 60);
          str = "".concat(_min2, "\u5206").concat(_sec2, "\u79D2");
        } else if (Number(absVal) < 60) {
          str = "".concat(Number(absVal), "\u79D2");
        }
        if (Number(val) < 0) {
          return "-".concat(str);
        }
        return "".concat(str);
      };
    },
    minuteTimeFilter: function minuteTimeFilter() {
      return function (val) {
        if (val == 0) {
          return '0';
        }
        // if (!val) {
        //     return '';
        // }
        var absVal = Math.abs(val);
        var minute = (Number(absVal) / 60).toFixed(2);
        if (Number(val) < 0) {
          return "-".concat(minute);
        }
        return "".concat(minute);
      };
    }
  },
  mounted: function mounted() {
    // 默认下拉时间
    this.dateOptions = [];
    for (var _i = 1; _i < 6; _i++) {
      var obj = {
        id: _i,
        value: 2021 + _i + '',
        label: 2021 + _i + ''
      };
      this.dateOptions.push(obj);
    }
    this.dateOptionsValue = [];
    // 获取当前年月 默认为当前年月
    this.orderTimeValue = new Date().getFullYear() + '';
    this.orderTimeValue1 = "".concat(this.orderTimeValue, "/").concat(new Date().getMonth() + 1);
    var val = this.orderTimeValue;
    var i = 1;
    // 最早从2022年4月开始
    if (this.orderTimeValue == '2022') {
      i = 4;
    }
    for (i; i < 13; i++) {
      var _obj = {
        id: i,
        value: "".concat(val, "/").concat(i),
        label: "".concat(val, "-").concat(i)
      };
      this.dateOptionsValue.push(_obj);
    }

    // 获取任务数据
    this.getTaskDetailFun();
    // 获取订单数据
    this.getOrderDataFun();
    // 销售行为数据
    this.getSaleDataFun();
  },
  methods: {
    // 除法精确计算
    accDiv: function accDiv(arg1, arg2) {
      var r1,
        r2,
        t1 = 0,
        t2 = 0;
      arg1 = arg1.toString();
      arg2 = arg2.toString();
      try {
        t1 = arg1.split('.').length;
      } catch (e) {}
      try {
        t2 = arg2.split('.').length;
      } catch (e) {}
      r1 = Number(arg1.replace('.', ''));
      r2 = Number(arg2.replace('.', ''));
      var num = Math.pow(10, t2 - t1);
      var num1 = r1 / r2;
      var newnum1 = Math.round(num1 * 100) / 100;
      var nownum1 = newnum1.toFixed(2);
      console.log('r1---', r1);
      console.log('r2---', r2);
      console.log('num1---', nownum1);
      return this.accMul(nownum1, num); //调用乘法
    },
    // 乘法精确计算
    accMul: function accMul(arg1, arg2) {
      console.log('arg1---', arg1);
      console.log('arg2---', arg2);
      var r1 = arg1.toString();
      var r2 = arg2.toString();
      var m = 0;
      try {
        m += arg1.toString().split('.')[1].length;
      } catch (e) {}
      try {
        m += arg2.toString().split('.')[1].length;
      } catch (e) {}
      return Number(r1.replace('.', '')) * Number(r2.replace('.', '')) / Math.pow(10, m);
    },
    //点击详情打开弹窗
    openAuditDialog: function openAuditDialog(data) {
      var _this2 = this;
      getAuditDetail({
        order_id: data.id
      }).then(function (respomse) {
        _this2.auditDetailData = respomse.data;
        _this2.$refs.auditDialog.showDialogFun();
      });
    },
    // 订单数据时间
    changeOptionsFun: function changeOptionsFun(val, index) {
      if (index != 1) {
        this.dateOptionsValue = [];
        var i = 1;
        if (this.orderTimeValue == '2022') {
          i = 4;
        }
        for (i; i < 13; i++) {
          var obj = {
            id: i,
            value: "".concat(val, "/").concat(i),
            label: "".concat(val, "-").concat(i)
          };
          this.dateOptionsValue.push(obj);
        }
      }
      if (index == 0) {
        this.orderTimeValue1 = this.dateOptionsValue[0].value;
      }
      this.getOrderDataFun();
    },
    // 获取任务数据
    getTaskDetailFun: function getTaskDetailFun() {
      var _this3 = this;
      this.taskLoading = true;
      // 调用接口
      getUserKpiInfo().then(function (res) {
        _this3.allDataObj = res.data;
        var _this = _this3.allDataObj;
        // 计算个人未完成数据
        var data1 = 0;
        var dataPercent1 = 0;
        if (Number(_this.individual_actual_sales) >= Number(_this.individual_target_sales)) {
          data1 = 0;
          dataPercent1 = 100;
        } else {
          data1 = (Number(_this.individual_target_sales) - Number(_this.individual_actual_sales)).toFixed(2);
          // dataPercent1 = (
          //     Number(Number(_this.individual_actual_sales) / Number(_this.individual_target_sales)) * 100
          // ).toFixed(2);
          dataPercent1 = (Number(_this3.moneyFun(_this3.allDataObj.individual_actual_sales)) / Number(_this3.moneyFun(_this3.allDataObj.individual_target_sales)) * 100).toFixed(2);
        }
        _this3.$set(_this3.allDataObj, 'percent1', Number(dataPercent1));
        _this3.$set(_this3.allDataObj, 'individual_no_target', data1);
        // 如果是主管的话还需要计算团队相关数据
        if (_this.is_leader == 1) {
          var data2 = 0;
          var dataPercent2 = 0;
          if (Number(_this.group_actual_sales) >= Number(_this.group_target_sales)) {
            data2 = 0;
            dataPercent2 = 100;
          } else {
            data2 = (Number(_this.group_target_sales) - Number(_this.group_actual_sales)).toFixed(2);
            // dataPercent2 = (Number(Number(_this.group_actual_sales) / Number(_this.group_target_sales)) * 100).toFixed(2);
            dataPercent2 = (Number(_this3.moneyFun(_this3.allDataObj.group_actual_sales)) / Number(_this3.moneyFun(_this3.allDataObj.group_target_sales)) * 100).toFixed(2);
          }
          _this3.$set(_this3.allDataObj, 'percent2', Number(dataPercent2));
          _this3.$set(_this3.allDataObj, 'group_no_target', data2);
        }
      }).finally(function () {
        _this3.taskLoading = false;
      });
    },
    // 获取订单数据
    getOrderDataFun: function getOrderDataFun() {
      var _this4 = this;
      this.orderLoading = true;
      var days = this.getMonthDays("".concat(this.orderTimeValue1, "/01"));
      var params = {
        order_time_start: parseInt(new Date("".concat(this.orderTimeValue1, "/01 00:00:00")).getTime() / 1000),
        order_time_end: parseInt(new Date("".concat(this.orderTimeValue1, "/").concat(days, " 23:59:59")).getTime() / 1000)
      };
      // 调用接口
      getIndividualOrderList(params).then(function (res) {
        _this4.tableOrderData = res.data.order_info;
        var info = res.data.commission_info;
        // if (this.tableOrderData.length > 0) {
        var obj = {
          cname: "\u4E2A\u4EBA\u6536\u6B3E\u603B\u989D\uFF1A".concat(info.total_amount, " \u5143"),
          pay_time: "\u4E2A\u4EBA\u53C2\u4E0E\u63D0\u6210\u7684\u5B66\u8D39\u603B\u989D\uFF1A".concat(info.total_commission_tuition, " \u5143"),
          pay_amount_detail: "\u4E2A\u4EBA\u63D0\u6210\uFF1A".concat(info.commission_fee, " \u5143"),
          isSource: 'hand',
          //为了和正常数据区分
          content1: '个人收款总额 = 订单审核通过时间在该月度内的收款总金额',
          content2: '个人参与提成的学费总额 = 订单审核通过时间在该月度内且学费已收齐的累计学费总额',
          content3: '个人提成 = 个人参与提成的学费总额 * 个人业绩提点'
        };
        _this4.tableOrderData.push(obj);
        // }
      }).finally(function () {
        _this4.orderLoading = false;
      });
    },
    // 获取那个月有几天
    getMonthDays: function getMonthDays(date1) {
      var date = new Date(date1);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var days = new Date(year, month, 0);
      return days.getDate();
    },
    // 销售行为数据
    getSaleDataFun: function getSaleDataFun() {
      var _this5 = this;
      this.saleLoading = true;
      // 调用接口
      getSalesActivityInfo().then(function (res) {
        var data = res.data;
        // this.tableSaleData = res.data;
        _this5.tableSaleData.obj1 = [{
          cname: data.user_name,
          time: data.individual_talk_time,
          avgTime: data.avg_individual_first_call_time,
          number_today: data.individual_sea_number_today,
          time_today: data.avg_individual_first_call_time_today
        }, {
          cname: 'A级优秀人员平均数据',
          time: data.super_level_avg_talk_time,
          avgTime: data.avg_super_level_first_call_time,
          number_today: data.super_level_sea_number_today,
          time_today: data.avg_super_level_first_call_time_today
        }];
        _this5.tableSaleData.obj2 = [{
          cname: data.user_name,
          individual1: _this5.percentNumberFun(data.individual_conversion_rate.general),
          individual2: _this5.percentNumberFun(data.individual_conversion_rate.importance),
          individual3: _this5.percentNumberFun(data.individual_conversion_rate.persistent)
        }, {
          cname: 'A级优秀人员平均数据',
          time: data.super_level_avg_talk_time,
          individual1: _this5.percentNumberFun(data.super_level_conversion_rate.general),
          individual2: _this5.percentNumberFun(data.super_level_conversion_rate.importance),
          individual3: _this5.percentNumberFun(data.super_level_conversion_rate.persistent)
        }];
      }).finally(function () {
        _this5.saleLoading = false;
      });
    },
    // 合计计算方法
    getSummaryFun: function getSummaryFun(_ref) {
      var row = _ref.row,
        column = _ref.column,
        rowIndex = _ref.rowIndex,
        columnIndex = _ref.columnIndex;
      if (rowIndex + 1 == this.tableOrderData.length) {
        if (columnIndex == 0) {
          return {
            rowspan: 1,
            colspan: 2
          };
        } else if (columnIndex == 1) {
          return {
            rowspan: 1,
            colspan: 2
          };
        } else if (columnIndex == 2) {
          return {
            rowspan: 1,
            colspan: 3
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    getCellClassNameFun: function getCellClassNameFun(_ref2) {
      var row = _ref2.row,
        column = _ref2.column,
        rowIndex = _ref2.rowIndex,
        columnIndex = _ref2.columnIndex;
      if (rowIndex + 1 == this.tableOrderData.length) {
        if (columnIndex == 0 || columnIndex == 1 || columnIndex == 2) {
          return 'bold-class';
        }
      }
    },
    // 单位转换/10000
    moneyFun: function moneyFun(money) {
      if (money == '-') {
        return '-';
      }
      var data = (Number(money) / 10000).toFixed(2);
      return data;
    },
    // 转换百分比数据
    percentNumberFun: function percentNumberFun(data) {
      if (data == '0') {
        return '0%';
      } else {
        var num = (Number(data) * 100).toFixed(2);
        return "".concat(num, "%");
      }
    }
  }
};