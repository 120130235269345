var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-header",
        {
          staticStyle: {
            height: "auto",
            padding: "0 12px",
            background: "rgb(250, 250, 250)",
          },
        },
        [
          _c(
            "el-row",
            {
              staticStyle: { "border-bottom": "26px solid #fafafa" },
              attrs: { gutter: 24 },
            },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 16, md: 8, lg: 8, xl: 6 } },
                [_c("Heads", { attrs: { name: _vm.title } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "padding-bot-30" },
        [
          _c(
            "el-card",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.taskLoading,
                  expression: "taskLoading",
                },
              ],
              staticClass: "box-card",
            },
            [
              _c("div", { staticClass: "box-title-task-class mar-bot-30" }, [
                _c("div", { staticClass: "title-class" }, [_vm._v("任务数据")]),
                _vm._v(" "),
                _c("div", { staticClass: "time-class" }, [
                  _vm._v(
                    "时间：" +
                      _vm._s(new Date().getFullYear()) +
                      "年" +
                      _vm._s(new Date().getMonth() + 1) +
                      "月"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "content-border-class", attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "mar-bot-10",
                          attrs: { xs: 12, sm: 12, md: 12, lg: 2, xl: 2 },
                        },
                        [
                          _c("div", { staticClass: "name-font-class" }, [
                            _vm._v("职级"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "name-font-content mar-top-20" },
                            [_vm._v(_vm._s(_vm.allDataObj.professional_level))]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.allDataObj.user_level != 0 &&
                      _vm.allDataObj.user_level != "-"
                        ? _c(
                            "el-col",
                            {
                              staticClass: "mar-bot-10",
                              attrs: { xs: 12, sm: 12, md: 12, lg: 3, xl: 3 },
                            },
                            [
                              _c("div", { staticClass: "name-font-class" }, [
                                _vm._v("所属定级"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "name-font-content mar-top-20" },
                                [
                                  _vm.allDataObj.user_level == 1
                                    ? _c("span", [_vm._v("A级")])
                                    : _vm.allDataObj.user_level == 2
                                    ? _c("span", [_vm._v("B级")])
                                    : _vm.allDataObj.user_level == 3
                                    ? _c("span", [_vm._v("C级")])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticClass: "mar-bot-10",
                          attrs: { xs: 24, sm: 24, md: 24, lg: 10, xl: 10 },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { xs: 12, sm: 8, md: 8, lg: 8, xl: 8 } },
                            [
                              _c("div", { staticClass: "font-36" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.moneyFun(
                                      _vm.allDataObj.individual_target_sales
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "mar-top-20 font-14" }, [
                                _vm._v("个人业绩指标(万元)"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { xs: 12, sm: 8, md: 8, lg: 8, xl: 8 } },
                            [
                              _c(
                                "div",
                                { staticClass: "font-36 green-mubiao-class" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.moneyFun(
                                        _vm.allDataObj.individual_actual_sales
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "mar-top-20 font-14" }, [
                                _vm._v("个人已完成（万元）"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { xs: 12, sm: 8, md: 8, lg: 8, xl: 8 } },
                            [
                              _c(
                                "div",
                                { staticClass: "font-36 red-mubiao-class" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.moneyFun(
                                        _vm.allDataObj.individual_no_target
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "mar-top-20 font-14" }, [
                                _vm._v("个人未完成（万元）"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              attrs: {
                                offset: 1,
                                xs: 22,
                                sm: 22,
                                md: 22,
                                lg: 22,
                                xl: 22,
                              },
                            },
                            [
                              _c("el-progress", {
                                staticClass: "progress-color",
                                attrs: {
                                  "stroke-width": 20,
                                  color: "rgba(10, 143, 103, 1)",
                                  percentage: _vm.allDataObj.percent1,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.allDataObj.is_leader == 1
                        ? _c(
                            "el-col",
                            {
                              staticClass: "border-left-all mar-bot-10",
                              attrs: { xs: 24, sm: 24, md: 24, lg: 9, xl: 9 },
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: { xs: 12, sm: 8, md: 8, lg: 8, xl: 8 },
                                },
                                [
                                  _c("div", { staticClass: "font-36" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.moneyFun(
                                          _vm.allDataObj.group_target_sales
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "mar-top-20 font-14" },
                                    [_vm._v("团队业绩指标(万元)")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: { xs: 12, sm: 8, md: 8, lg: 8, xl: 8 },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "font-36 green-mubiao-class",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.moneyFun(
                                            _vm.allDataObj.group_actual_sales
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "mar-top-20 font-14" },
                                    [_vm._v("团队已完成（万元）")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: { xs: 12, sm: 8, md: 8, lg: 8, xl: 8 },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "font-36 red-mubiao-class" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.moneyFun(
                                            _vm.allDataObj.group_no_target
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "mar-top-20 font-14" },
                                    [_vm._v("团队未完成（万元）")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    offset: 1,
                                    xs: 22,
                                    sm: 22,
                                    md: 22,
                                    lg: 22,
                                    xl: 22,
                                  },
                                },
                                [
                                  _c("el-progress", {
                                    staticClass: "progress-color",
                                    attrs: {
                                      "stroke-width": 20,
                                      color: "rgba(10, 143, 103, 1)",
                                      percentage: _vm.allDataObj.percent2,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-card",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.orderLoading,
                  expression: "orderLoading",
                },
              ],
              staticClass: "box-card mar-top-20",
            },
            [
              _c("div", { staticClass: "flex-class mar-bot-30" }, [
                _c("div", { staticClass: "box-title-task-class" }, [
                  _c("div", { staticClass: "title-class" }, [
                    _vm._v("订单&提成"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {},
                  [
                    _vm._v(
                      "\n                    时间：\n                    "
                    ),
                    _c(
                      "el-select",
                      {
                        attrs: { size: "small", placeholder: "请选择" },
                        on: {
                          change: function (val) {
                            return _vm.changeOptionsFun(val, 0)
                          },
                        },
                        model: {
                          value: _vm.orderTimeValue,
                          callback: function ($$v) {
                            _vm.orderTimeValue = $$v
                          },
                          expression: "orderTimeValue",
                        },
                      },
                      _vm._l(_vm.dateOptions, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        attrs: { size: "small", placeholder: "请选择" },
                        on: {
                          change: function (val) {
                            return _vm.changeOptionsFun(val, 1)
                          },
                        },
                        model: {
                          value: _vm.orderTimeValue1,
                          callback: function ($$v) {
                            _vm.orderTimeValue1 = $$v
                          },
                          expression: "orderTimeValue1",
                        },
                      },
                      _vm._l(_vm.dateOptionsValue, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "order-table-class" },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableOrderData,
                        "span-method": _vm.getSummaryFun,
                        "cell-class-name": _vm.getCellClassNameFun,
                        border: "",
                        "header-cell-style": {
                          background: "#F8F9FB",
                          color: "#222222",
                        },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "cname",
                          label: "学员姓名",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.isSource != "hand"
                                  ? _c("div", [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(scope.row.cname) +
                                          "\n                            "
                                      ),
                                    ])
                                  : _c(
                                      "div",
                                      { staticClass: "font-bold-black" },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              effect: "dark",
                                              content: scope.row.content1,
                                              placement: "bottom",
                                            },
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "el-icon-question flex-center-center",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "font-14" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(scope.row.cname)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "pay_time",
                          label: "缴费日期",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.isSource != "hand"
                                  ? _c("div", [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(scope.row.pay_time) +
                                          "\n                            "
                                      ),
                                    ])
                                  : _c(
                                      "div",
                                      { staticClass: "font-bold-black" },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              effect: "dark",
                                              content: scope.row.content2,
                                              placement: "bottom",
                                            },
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "el-icon-question flex-center-center",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "font-14" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(scope.row.pay_time)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "pay_amount_detail",
                          label: "缴费用途",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.isSource != "hand"
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        scope.row.pay_amount_detail,
                                        function (item, index) {
                                          return _c("div", { key: index }, [
                                            item.type == 1
                                              ? _c("div", [
                                                  _c("span", [
                                                    _vm._v("报名费"),
                                                  ]),
                                                  _c("span", [
                                                    _vm._v(
                                                      "：" + _vm._s(item.amount)
                                                    ),
                                                  ]),
                                                  _c("br"),
                                                ])
                                              : item.type == 2
                                              ? _c("div", [
                                                  _c("span", [_vm._v("定金")]),
                                                  _c("span", [
                                                    _vm._v(
                                                      "：" + _vm._s(item.amount)
                                                    ),
                                                  ]),
                                                  _c("br"),
                                                ])
                                              : item.type == 3
                                              ? _c("div", [
                                                  _c("span", [_vm._v("学费")]),
                                                  _c("span", [
                                                    _vm._v(
                                                      "：" + _vm._s(item.amount)
                                                    ),
                                                  ]),
                                                  _c("br"),
                                                ])
                                              : item.type == 4
                                              ? _c("div", [
                                                  _c("span", [
                                                    _vm._v("资料费"),
                                                  ]),
                                                  _c("span", [
                                                    _vm._v(
                                                      "：" + _vm._s(item.amount)
                                                    ),
                                                  ]),
                                                  _c("br"),
                                                ])
                                              : item.type == 5
                                              ? _c("div", [
                                                  _c("span", [
                                                    _vm._v("语言培训费"),
                                                  ]),
                                                  _c("span", [
                                                    _vm._v(
                                                      "：" + _vm._s(item.amount)
                                                    ),
                                                  ]),
                                                  _c("br"),
                                                ])
                                              : item.type == 6
                                              ? _c("div", [
                                                  _c("span", [
                                                    _vm._v("普通留学申请费"),
                                                  ]),
                                                  _c("span", [
                                                    _vm._v(
                                                      "：" + _vm._s(item.amount)
                                                    ),
                                                  ]),
                                                  _c("br"),
                                                ])
                                              : _vm._e(),
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "font-bold-black" },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              effect: "dark",
                                              content: scope.row.content3,
                                              placement: "bottom",
                                            },
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "el-icon-question flex-center-center",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "font-14" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row
                                                          .pay_amount_detail
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "money",
                          label: "收款金额（元）",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "create_time",
                          label: "订单提交时间",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { align: "center", prop: "", label: "审核详情" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.isSource != "hand"
                                  ? _c("div", [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "blue-cursor",
                                          on: {
                                            click: function ($event) {
                                              return _vm.openAuditDialog(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("审核详情")]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "audit_time",
                          label: "审核通过时间",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "order-info-font" }, [
                    _vm._v("系统展示个人提成暂不考虑退款情况"),
                  ]),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-card",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.saleLoading,
                  expression: "saleLoading",
                },
              ],
              staticClass: "box-card mar-top-20",
            },
            [
              _c("div", { staticClass: "box-title-task-class mar-bot-30" }, [
                _c("div", { staticClass: "title-class" }, [
                  _vm._v("销售行为数据"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "table-method-class" },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "90%" },
                      attrs: {
                        data: _vm.tableSaleData.obj1,
                        border: "",
                        "header-cell-style": {
                          background: "#F8F9FB",
                          color: "#222222",
                        },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { align: "center", prop: "cname", label: "" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "time",
                          label: "一周通话总时长（分钟）",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  _vm._s(_vm.minuteTimeFilter(scope.row.time))
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "avgTime",
                          label: "一周数据平均跟进时效",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.sumTimeFilter(scope.row.avgTime)
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "number_today",
                          label: "今日进入私海数据量",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [_vm._v(_vm._s(scope.row.number_today))]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "time_today",
                          label: "今日数据平均跟进时效",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.sumTimeFilter(scope.row.time_today)
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      staticClass: "mar-top-20",
                      staticStyle: { width: "90%" },
                      attrs: {
                        data: _vm.tableSaleData.obj2,
                        border: "",
                        "header-cell-style": {
                          background: "#F8F9FB",
                          color: "#222222",
                        },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { align: "center", prop: "cname", label: "" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "individual1",
                          label: "一般意向转化率",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "individual2",
                          label: "重点意向转化率",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "individual3",
                          label: "长期跟进转化率",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("auditDialog", {
        ref: "auditDialog",
        attrs: { form: _vm.auditDetailData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }