import request from '@/utils/request';

// 获取所有职级列表
export function getProfessionalLevelList(data) {
  return request({
    url: '/clientserver.php/User/getProfessionalLevelList',
    method: 'post',
    data: data
  });
}

// 可配置业绩指标的职级
export function getConfigurableProfessionalLevelList(data) {
  return request({
    url: '/clientserver.php/User/getConfigurableProfessionalLevelList',
    method: 'post',
    data: data
  });
}

// 业绩指标配置列表
export function getSalesKpiConfigList(data) {
  return request({
    url: '/clientserver.php/User/getSalesKpiConfigList',
    method: 'post',
    data: data
  });
}

// 业绩指标配置新增/编辑
export function editSalesKpiConfig(data) {
  return request({
    url: '/clientserver.php/User/editSalesKpiConfig',
    method: 'post',
    data: data
  });
}

// 业绩指标配置删除
export function removeSalesKpiConfig(data) {
  return request({
    url: '/clientserver.php/User/removeSalesKpiConfig',
    method: 'post',
    data: data
  });
}

// 业绩指标配置详情
export function getSalesKpiConfigInfo(data) {
  return request({
    url: '/clientserver.php/User/getSalesKpiConfigInfo',
    method: 'post',
    data: data
  });
}

// 业绩指标配置修改记录
export function getSalesKpiConfigDiffLogList(data) {
  return request({
    url: '/clientserver.php/User/getSalesKpiConfigDiffLogList',
    method: 'post',
    data: data
  });
}

// 个人统计-任务数据
export function getUserKpiInfo(data) {
  return request({
    url: '/clientserver.php/User/getUserKpiInfo',
    method: 'post',
    data: data
  });
}

// 个人统计-订单数据
export function getIndividualOrderList(data) {
  return request({
    url: '/clientserver.php/CustomerOrder/getIndividualOrderList',
    method: 'post',
    data: data
  });
}

// 个人统计-销售行为数据
export function getSalesActivityInfo(data) {
  return request({
    url: '/clientserver.php/User/getSalesActivityInfo',
    method: 'post',
    data: data
  });
}

// 销售提成数据列表
export function getUserCommissionData(data) {
  return request({
    url: '/clientserver.php/UserCommissionData/getUserCommissionData',
    method: 'post',
    data: data
  });
}